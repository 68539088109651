import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "A Veteran's Call to Action",
  "author": "Anthony",
  "date": "2018-02-09T06:26:52.000Z",
  "categories": ["Military Transition"],
  "slug": "a-veterans-call-to-action",
  "draft": false,
  "meta_title": "A Veteran's Call to Action"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`When I left the Army in 2007, I had been away from combat for one year and was in the process of transitioning to life as a student. As ridiculous as this sounds, I was more afraid of going to class than receiving a 0300 Dustoff mission. I was afraid because I didn’t know how to act as a civilian, I was zoning out in class thinking about Iraq, I was surrounded by people who had never experienced combat, and I believed I had lost my identity.`}</p>
    <p><strong parentName="p">{`Warriors and the Village`}</strong></p>
    <p>{`Hundreds of years ago, warriors left their villages to fight wars. Sometimes they were away from home for years. Villages knew their warriors, the same as small towns like Bandera, TX, and Elmira, NY know their warriors today. When warriors in the past returned to their villages, they were welcomed back with open arms. Every person in the village understood what their warrior had been through. Warriors were not held on pedestals but were respected because villagers knew they were fighting and defending the community. This understanding helped make the transition from warrior to the farmer a relatively smooth one. This understanding eliminated misconceptions about combat and what it meant to be a warrior transitioning to a new profession. Older generations of warriors in a village were the norm, which reinforced the re-assimilation of younger warriors returning home. Our community understood us.`}</p>
    <p>{`Fast forward to the first half of the 20th century. Many communities were smaller than they are today. There were still generations of warriors in these communities. Organizations like `}<a parentName="p" {...{
        "href": "http://www.legion.org/"
      }}>{`The American Legion`}</a>{` and the `}<a parentName="p" {...{
        "href": "http://www.vfw.org/"
      }}>{`VFW`}</a>{` provided a common place where warriors could come together. Service was mandatory and generations of warriors were still abundant.`}</p>
    <p>{`It’s different today in the early 21st century. Technology and industrialization have grown our communities and formed our great cities. Our military is vastly improved. We have superior combat technology and our warriors are better trained and educated. We do more with fewer warriors, resulting in only 1 percent of our population serving at any given time. Generations of warriors are widening. Today, a warrior comes back from years of fighting, separates from service, and is thrown into a society that does not understand what he has been through.`}</p>
    <p><strong parentName="p">{`Who can help Warriors?`}</strong></p>
    <p>{`So how do we do a better job of veteran assimilation? I could make the argument that our government and local municipalities should solve the homeless veteran battle, veteran unemployment, and the lack of healthcare resources. I could also make a case for joining the local VFW or American Legion post. I believe it’s our duty, as fellow veterans, to welcome back our brother and sister warriors. It’s our duty, as veterans, to be the community that assists with the transition from warrior to a farmer, so to speak. No one can do this better than veterans. And every veteran can make an individual difference. With social media, technology, and cell phone, we’re all connected and closer than we were 10 years ago. A couple of months ago I discovered that a flight medic I served with was retiring. I got this from his Facebook timeline. The village is not dead, it’s just changed a bit.`}</p>
    <p><strong parentName="p">{`Here are some examples of how veterans can help veterans:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Telling`}</strong>{` your transition story to a veteran – Warriors need to understand that they are experiencing nothing that another warrior before them has not been through. This can assist with avoiding isolation from loved ones and assist with combating PTSD – resulting in encouragement to seek professional help.`}</li>
      <li parentName="ul"><strong parentName="li">{`Resume writing`}</strong>{` – A veteran assisted me in translating my military service to private-sector understanding. I was completely lost without his assistance. The process involves digging up old OERs/NCOERs, looking at past assignments, and reflecting on your career. Assistance from a warrior who made the transition provides validation that what you did while serving can translate to the private sector. It will help you to understand how you can contribute after service and what gaps need to be filled through education and training to be successful. This is why I founded `}<a parentName="li" {...{
          "href": "http://www.purepost.co/"
        }}>{`Purepost.`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Coaching`}</strong>{` newly separated veterans on how to interact in the private sector – I currently hold monthly Skypes with two buddies who are in the process of leaving service. I tell them about my mistakes and what I thought when I was interacting for the first time in the private sector. The questions they ask me are the same questions I had back in 2007.`}</li>
      <li parentName="ul"><strong parentName="li">{`Connecting`}</strong>{` veterans with civilians in a social setting – I knew very few non-military folks when I left the Army. It took me a while to make civilian friends. After I made a few good friends, I could see they were just like me. In fact, I would argue having new friends, who have never served, is vital to the transition.`}</li>
      <li parentName="ul"><strong parentName="li">{`Offering`}</strong>{` assistance – When I was an Army Aviator, crew coordination was paramount to the safety and success of all missions. A vital element to crew coordination is “Offer Assistance.” Crewmembers offer assistance anytime a crewmember sees or recognizes anything that posses a hazard to flight. This could mean taking the controls if the pilot on the controls is having difficulty, or questioning another crewmember prior to taking action. All warriors in combat offer assistance. It’s a matter of life and death. Why should this be any different after-service? If you meet a fellow warrior, ask how they’re doing. If you feel a connection, exchange emails or phone numbers. I consider any day I meet a fellow warrior in the San Francisco Bay Area to be a good day. We’re few and far between in this area and it’s good to know we can lean on each other if needed. Even if it’s for a discussion over a late-night beer or coffee.`}</li>
    </ul>
    <p>{`The days of the village welcoming the warrior back home are still possible. The village is now virtual and comprised of veterans. This past year I changed my profile picture on FB to a photo of me in my dress blues. I later noticed my newsfeed filled with dozens of profile picture updates. Fellow warriors were changing their profile pictures to high-speed photos of them kitted up, wearing their dress uniform, or busting a pose in front of a Black Hawk. We do this because we’re proud of our service. We do this because other veterans are proud of our service. And we do this to show solidarity with our brothers and sisters to cope with. We may be hard to find in the real world, but we’re all discoverable and accessible in the virtual world.`}</p>
    <p>{`I challenge all warriors to reflect on how they can welcome a warrior back to our village. We’ve been fighting for over a decade. We all have suffered, lost brothers and sisters, many have physical wounds, and we all have emotional wounds. We understand each other. We can’t sit around and wait for the government to do something for us and we can’t count on an organization to solve our problems. We all have the power to have a direct impact on a warrior. That’s something worth living for.`}</p>
    <p>{`Anthony`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      